import React, { Component } from 'react';
import axios from 'axios';
import Camera, { FACING_MODES }from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import './App.css';

import logo from './logo.png';

import { Button } from "react-bulma-components/full";

class App extends Component {
  constructor() {
    super();
    this.state = {
      lastImage: "",
      toggleCamera: 0,
    };
    this.sendPhototoServer = this.sendPhototoServer.bind(this);
    this.reTakePicture = this.reTakePicture.bind(this);
    this.toggleCamera = this.toggleCamera.bind(this);
  }

  onTakePhoto (dataUri) {
    this.setState({
      lastImage: dataUri
    })
  }

  sendPhototoServer() {
    const thisis = this;
    const image = this.state.lastImage;
    axios.post('https://status.djpmedia.nl:/api.php', {
      image: image,
    })
    .then(function (response) {
      console.log(response);
      thisis.setState({
        lastImage: "",
        toggleCamera: 0
      })
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  reTakePicture() {
    this.setState({
        lastImage: "",
        toggleCamera: 0
      })
  }

  toggleCamera() {
    this.setState({
      toggleCamera: this.state.toggleCamera ? 0 : 1
    })
  }

  

  render() { 
    const thisis = this;
    const state = this.state;

    function ToggleContent() {
      const toggleImage = state.toggleCamera;
      if (!toggleImage) {
        return (
          <div>
            <p>Maak nu een sefie en stuur deze in voor het scherm!<br /><br /></p>
            <Button
                  color="primary"
                  size="large"
                  rounded
                  onClick={thisis.toggleCamera}
                >
                  Maak een foto
                </Button>
          </div>
        )
      }

      return <ShowImage />
    }

    function ShowImage() {
      const hasImage = state.lastImage;
      if (hasImage) {
        return (
            <div>
              <img src={hasImage} alt="fun" /><br />
              <Button
                  color="primary"
                  size="large"
                  rounded
                  outlined
                  onClick={thisis.sendPhototoServer}
                >
                  Verstuur naar het scherm
                </Button><br />
                <p><br /><Button size="small" color="danger" rounded outlined  onClick={thisis.reTakePicture}>Neem foto opnieuw</Button></p>
            </div>            
        );
      }
      return <Camera
          onTakePhoto = { (dataUri) => { thisis.onTakePhoto(dataUri); } }
          idealFacingMode = {FACING_MODES.ENVIRONMENT}
          //idealResolution = {{width: 480, height: 640}}
          imageType = 'jpg'
          isFullscreen = 'false'
          isImageMirror = 'false'
        />;
    }


        return (
          <div className="App">
            <header className="App-header">
              <img src={logo} alt='logo' />
              <p>Koloniefeest Selfie cam</p>
            </header>
            <section>
              <ToggleContent />
            </section>
          </div>
      );
    };
}

export default App;
